import LazyLoad from "react-lazyload";
import CodeFromRemote from "../../../../src/components/freestanding/utils/codefromremote";
import loginVideoMP4 from "../../../../src/images/articles/react-native/login-small.mp4";
import signupVideoMP4 from "../../../../src/images/articles/react-native/signup-profile-settings-small.mp4";
import loginVideoWebm from "../../../../src/images/articles/react-native/login.webm";
import signupVideoWebm from "../../../../src/images/articles/react-native/signup-profile-settings-small.webm";
import * as React from 'react';
export default {
  LazyLoad,
  CodeFromRemote,
  loginVideoMP4,
  signupVideoMP4,
  loginVideoWebm,
  signupVideoWebm,
  React
};