/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
require("normalize.css")
require("prismjs/themes/prism.css")
require("remark-admonitions/styles/classic.css")

exports.onClientEntry = () => {
  window.dataLayer = window.dataLayer || []
  window.originalPushFunction = window.dataLayer.push
  window.dataLayer.push = function () {
    for (var args = arguments, i = 0; i < args.length; i++) {
      if (!window.google_tag_manager) {
        if (
          (args[i].length === 3 &&
            args[i][0] === "consent" &&
            args[i][1] === "update") ||
          (args[i] &&
            args[i].event &&
            -1 < args[i].event.indexOf("consent_status"))
        ) {
          window.dataLayer.unshift(args[i])
          continue
        }
      }
      window.originalPushFunction.call(window.dataLayer, args[i])
    }
  }

  window.dataLayer.push({
    event: "site",
    site: {
      channel: "website",
      env:
        window.location.hostname.endsWith("ory.sh") ||
        window.location.hostname.endsWith("ory.dev") // This is an alternate site we use to publish things on hacker news.
          ? "prod"
          : "dev",
    },
  })
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  const did = getCookie("visitorUUID")

  // Generate and set visitorUUID cookie with expiry after 1 year i.e. 365 days
  // Future sequential stuff that is dependent on consent banner goes here
  window.dataLayer.push({
    event: "ident",
    ident: {
      did: did,
    },
  })

  // page view and history change
  window.dataLayer.push({ track: undefined })
  window.dataLayer.push({
    event: "track",
    action: "page_view",
  })
  function getCookie(name) {
    let cookie = {}
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=")
      cookie[k.trim()] = v
    })
    return cookie[name]
  }
  // UUID generator
  // Private array of chars to use
  var CHARS =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("")

  // RFC4122v4 compliant uuid generator
  Math.uuid = function () {
    var chars = CHARS,
      uuid = new Array(36),
      rnd = 0,
      r
    for (var i = 0; i < 36; i++) {
      if (i === 8 || i === 13 || i === 18 || i === 23) {
        uuid[i] = "-"
      } else if (i === 14) {
        uuid[i] = "4"
      } else {
        if (rnd <= 0x02) rnd = (0x2000000 + Math.random() * 0x1000000) | 0
        r = rnd & 0xf
        rnd = rnd >> 4
        uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r]
      }
    }
    return uuid.join("")
  }
}
